import "./App.css";
import "./moji.css";
import React from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";

import { abi, contractAddress } from "./contract.json";
import { isMobile } from "react-device-detect";
import { ethers } from "ethers";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      web3: "",
      walletAddress: "",
      walletBalance: "",
      nftBalance: "",

      mojiContract: "",
      contractAddress: contractAddress,
      contractAbi: abi,

      ethToPay: 0,

      provider: '',

      numberOfNFTS: 1,

      totalTokensInSupply: 0,

      mainSaleOpen: false,
      presaleOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.LoadEthWalletAndContract = this.LoadEthWalletAndContract.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //Web3

    //ideally you want to call the mintAmountLeftForWallet method before allowing them to mint.
    //This will return the number of nfts they have left to mint. (if by chance they surpas it than the smart contract will deny the transaction)
    
    if (isMobile) {
      this.mintFromContract();
    }
    else
    {
      this.mintFromContract();
    }
  };

  mintFromContract() {
    const mintTotal = this.state.numberOfNFTS;

    if (mintTotal >= 1 && this.state.nftBalance < 20) {
      var tierOneValue = this.state.web3.utils.toWei(
        (0.1 * mintTotal).toString(),
        "ether"
      );

      if (this.state.mainSaleOpen) {
        const el = this;
        this.state.mojiContract.methods
          .mainSale(this.state.numberOfNFTS)
          .estimateGas({ gas: 5000000 }, function (error, gasAmount) {
            if (gasAmount == 5000000) console.log("Method ran out of gas");

            el.setState({ gasLimit: gasAmount });
          });
        this.state.mojiContract.methods
          .mainSale(this.state.numberOfNFTS)
          .send({
            from: this.state.walletAddress,
            value: tierOneValue,
            gas: this.state.gasLimit,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .then((res) => {
            console.log(res);
          });
      } else if (this.state.presaleOpen) {
        const el = this;

        this.state.mojiContract.methods
          .preSale(this.state.numberOfNFTS)
          .estimateGas({ gas: 5000000 }, function (error, gasAmount) {
            if (gasAmount == 5000000) console.log("Method ran out of gas");

            el.setState({ gasLimit: gasAmount });
          });

        this.state.mojiContract.methods
          .preSale(this.state.numberOfNFTS)
          .send({
            from: this.state.walletAddress,
            value: tierOneValue,
            gas: this.state.gasLimit,
          })
          .on("confirmation", function (confirmationNumber, receipt) {})
          .then((res) => {
            console.log(res);
          });
      }
    }
  }

  handleInputChange = (event) => {
    event.preventDefault();
  };

  handleChange(event) {
    this.setState({ numberOfNFTS: event.target.value });
  }

  componentDidMount() {
    if (window.ethereum) {
      var nestedThis = this;
      window.ethereum.on("accountsChanged", function (accounts) {
        nestedThis.LoadEthWalletAndContract();
      });
    }
  }

  async loadEthDetails()
  {
    await this.setState({provider : new ethers.providers.Web3Provider(window.ethereum, "any")});
    var acc = this.state.provider.send("eth_requestAccounts", []);

    await acc.then(res=>{
      this.setState({walletAddress: res[0]})

      var bal =  this.state.provider.getBalance(this.state.walletAddress)
    
      bal.then(res=>{
        this.setState({walletBalance: ethers.utils.formatEther(res)})
        document.getElementById("connectBTN").style.display = "none";
      })
    })

    await this.setState({mojiContract: new ethers.Contract(this.state.contractAddress, this.state.contractAbi, this.state.provider)})

    var nftbal = this.state.mojiContract.balanceOf(this.state.walletAddress)
    nftbal.then(res=>{
      this.setState({nftBalance: ethers.utils.formatUnits(res, 0)})
    })

    var preSale = this.state.mojiContract.presaleOpen()
    preSale.then(res=>{
      this.setState({presaleOpen: res})
      if (res) {
        document.getElementById("phaseOpen").innerText =
          "Current Phase : Presale";
      }
    })

    var mainsale = this.state.mojiContract.mainSaleOpen()
    mainsale.then(res=>{
      this.setState({mainSaleOpen: res})
      if (res) {
        document.getElementById("phaseOpen").innerText =
          "Current Phase : Mainsale";
      }
    })

    var totalToken = this.state.mojiContract.totalToken()
    totalToken.then(res=>{
      this.setState({totalTokensInSupply:  ethers.utils.formatUnits(res, 0)})
    }) 
  }

  async mintEthers()
  {
    const signer = this.state.provider.getSigner()
    const signerContract = await this.state.mojiContract.connect(signer);
    console.log(signerContract)

    if(this.state.presaleOpen)
    {
      
      var options = { gasLimit: 150000, maxFeePerGas: 150000000000, maxPriorityFeePerGas: 2000000000, value: ethers.utils.parseEther(`${this.state.numberOfNFTS * 0.1}`) };

      var presale = signerContract.preSale(this.state.numberOfNFTS, options)
      presale.then(res=>{
        console.log(res)
        
      })
    }

    if(this.state.mainSaleOpen)
    {
      
      var options = { gasLimit: 150000, maxFeePerGas: 200000000000, maxPriorityFeePerGas: 2000000000, value: ethers.utils.parseEther(`${this.state.numberOfNFTS * 0.1}`) };

      var mainsale = signerContract.mainSale(this.state.numberOfNFTS, options)
      mainsale.then(res=>{
        console.log(res)
        
      })
    }
  }


  LoadEthWalletAndContract() {

    this.loadEthDetails();


    /* if (window.ethereum) {
      this.state.web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/c8b2b5b2602146a1a75063795e90fd1f") || Web3.givenProvider || window.ethereum  );

      this.state.web3.eth.net.getNetworkType().then((network) => {
        //change to "main" after launching to mainnet
        if (network == "main") {
          //get account address
          this.state.web3.eth
            .requestAccounts()
            .then((acc) => {
              this.setState({ walletAddress: acc[0] });

              //get account eth balance
              this.state.web3.eth.getBalance(acc[0]).then((bal) => {
                document.getElementById("connectBTN").style.display = "none";

                this.setState({
                  walletBalance: this.state.web3.utils
                    .fromWei(bal, "ether")
                    .substring(0, 6),
                });
              });

              //initalize contract
              this.setState({
                mojiContract: new this.state.web3.eth.Contract(
                  abi,
                  contractAddress
                ),
              });

              //get balance of nfts in wallet
              this.state.mojiContract.methods
                .balanceOf(this.state.walletAddress)
                .call({ from: this.state.walletAddress })
                .then((result) => {
                  this.setState({ nftBalance: result });
                });

              this.state.mojiContract.methods
                .presaleOpen()
                .call({ from: this.state.walletAddress })
                .then((result) => {
                  if (result) {
                    document.getElementById("phaseOpen").innerText =
                      "Current Phase : Presale";
                  }
                  this.setState({ presaleOpen: result });
                });

              this.state.mojiContract.methods
                .mainSaleOpen()
                .call({ from: this.state.walletAddress })
                .then((result) => {
                  if (result) {
                    document.getElementById("phaseOpen").innerText =
                      "Current Phase : Mainsale";
                  }
                  this.setState({ mainSaleOpen: result });
                });

              this.state.mojiContract.methods
                .totalToken()
                .call({ from: this.state.walletAddress })
                .then((result) => {
                  this.setState({ totalTokensInSupply: result });
                });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          alert("Please connect to eth mainnet testnet!");
        }
      });
    } else {
      if (isMobile) {
        window.location.href =
          "https://metamask.app.link/dapp/mint.thelifeofmoji.com/";
      }
      alert("Please install metamask!");
    } */
  }

  // componentDidMount(){
  //     this.inputFullNameRef.current.focus()
  // }
  render() {
    return (
      <div class="container">
        <div class="logo">
          <img
            src="https://www.thelifeofmoji.com/wp-content/uploads/2021/10/1-Senza titolo-2.png"
            alt="The Life of Moji"
            width="150"
            height="150"
          ></img>
        </div>
        <div class="headcent"></div>
        <div class="head" id="connectBTN">
          <button
            class="connectbtn"
            onClick={() => {
              this.LoadEthWalletAndContract();
            }}
          >
            Connect wallet
          </button>
        </div>
        {/* Moji Text  */}
        <div class="space"></div>
        <div class="main">
          <h1 class="title right m-0">Welcome to</h1>
          <h1 class="title right m-0">the INCREDIBLE LIFE of MOJI!</h1>
          <p class="text right m-top">
            You are witnessing the WORLD’s FIRST ANIMATED SERIES based on our
            NFT community! By MINTING your RARE NFT, you will own particular
            rights to your UNIQUE NFT character in the show! You will have
            access to EXCLUSIVE content, events, and exciting EXPERIENCES that
            only a chosen few will enjoy! Our AWARD-WINNING team of Producers,
            Animators, and Writers are working diligently to propel MOJI’s
            explosive journey! MINT NOW and Join the citizens of "The City of
            Lights!"
          </p>
          <p class="text right m-top">
            You will be able to brag to all of your family and friends that the
            character you own was on an episode of “The Life of Moji.” Each
            character you MINT can potentially become a character that has a
            reoccurring role on the show. We hid 22 Supermoji's inside the
            collection, these NFTs are extremely rare! MINT a citizen NOW, and
            let’s make history together with “The Life of Moji!”
          </p>
          <div class="sample-gallery">
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/37.png"
              alt="Moji Sample 1"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/35.png"
              alt="Moji Sample 2"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/supermoji-3.jpg"
              alt="Moji Sample 3"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/supermoji-1.jpg"
              alt="Moji Sample 4"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/25.png"
              alt="Moji Sample 5"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/supermoji-2.jpg"
              alt="Moji Sample 6"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/34.png"
              alt="Moji Sample 7"
              class="moji-sample"
            ></img>
            <img
              src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/110.png"
              alt="Moji Sample 8"
              class="moji-sample"
            ></img>
          </div>
        </div>
        {/* Mint Section */}
        <div class="mint-obj">
          <div class="moji-sect">
            <div class="sidebar-head">
              <div class="mojilogo">
                <img
                  src="https://www.thelifeofmoji.com/wp-content/uploads/2021/12/moji-front.png"
                  alt="The Life of Moji"
                  width="200"
                  height="200"
                ></img>
              </div>
              <div class="infowallet">
                <p class="wallet-txt right">Your Address:</p>
                <p class="wallet-info right">{this.state.walletAddress}</p>
                <p class="wallet-txt right">Your Wallet Balance:</p>
                <p class="wallet-info right">{this.state.walletBalance}</p>
                <p class="wallet-txt right">NFT in wallet:</p>
                <p class="nft-balance right">{this.state.nftBalance}</p>
              </div>
            </div>

            <div class="mint-price">
              <h1 class="text right m-0 white" id="phaseOpen">
                Current Phase : Presale
              </h1>
              <h1 class="text right m-0 white" id="totalTokens">
                Total Minted : {this.state.totalTokensInSupply} / 10,000
              </h1>
              <h1 class="title right m-0">
                Current Price : {(this.state.numberOfNFTS * 0.1).toFixed(2)} ETH
                Total
              </h1>
            </div>

            <div class="mint-numb">
              <input
                class="count-moji"
                type="number"
                value={this.state.numberOfNFTS}
                min="1"
                max="20"
                onChange={this.handleChange}
              />
              <div class="text center info-txt">
                ENTER THE AMOUNT OF MOJI YOU WOULD LIKE TO COLLECT
              </div>
            </div>
            <button
              class="mintbtn"
              onClick={() => {
                this.mintEthers();
              }}
            >
              Mint Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
